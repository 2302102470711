<template>
    <Combobox
        v-model="selectedOption"
        :nullable="!required"
        as="div">
        <div class="flex items-center justify-between">
            <ComboboxLabel
                v-if="label"
                class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300 mb-1">
                {{ label }}<span v-if="required">*</span>
            </ComboboxLabel>
            <div
                v-if="$slots.inputHelper"
                class="text-sm">
                <slot name="inputHelper" />
            </div>
        </div>
        <div class="relative">
            <ComboboxInput
                class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 dark:text-gray-300 shadow-sm ring-1 ring-inset ring-gray-300
                focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6
                disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 disabled:shadow-none disabled:cursor-not-allowed
                after:content-[attr(placeholder)] after:absolute"
                :placeholder="placeholder"
                :display-value="(option) => (option) ? option[displayKey] : null"
                :disabled="disabled"
                @focus="openOptions = true"
                @change="onType($event.target.value)"
                @blur="handleBlur" />

            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <Icon
                    name="heroicons:chevron-up-down"
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true" />
            </ComboboxButton>

            <ComboboxButton
                v-if="!required && selectedOption"
                class="absolute inset-y-0 right-0 flex items-center rounded-r-md focus:outline-none me-[32px]"
                @click="clearInput()">
                <Icon
                    name="heroicons:x-mark-16-solid"
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true" />
            </ComboboxButton>

            <ComboboxOptions
                v-if="fetchOnSearch ? query.length > searchThreshold : filteredOptions?.length > 0"
                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-gray-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                :static="openOptions">
                <ComboboxOption
                    v-if="fetchOnSearch && pending"
                    selected
                    disabled>
                    <li class="flex justify-center overflow-hidden">
                        <LoadingSpinner size="md" />
                    </li>
                </ComboboxOption>
                <ComboboxOption
                    v-else-if="filteredOptions.length === 0"
                    disabled>
                    <li class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 dark:text-gray-300">
                        {{ $t('globalSearch.noResults') }}
                    </li>
                </ComboboxOption>
                <ComboboxOption
                    v-for="option in filteredOptions"
                    v-else
                    :key="option[identifier]"
                    v-slot="{ active, selected }"
                    :disabled="option[disabledKey]"
                    :value="option"
                    as="template"
                    @click="openOptions = false">
                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-primary text-white' : 'text-gray-900 dark:text-gray-300']">
                        <span class="block">
                            <slot
                                v-bind="option"
                                name="option">
                                {{ option[displayKey] }}
                            </slot>
                        </span>

                        <span
                            v-if="selected"
                            :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-primary']" />
                    </li>
                </ComboboxOption>
            </ComboboxOptions>
        </div>
    </Combobox>
</template>
<script setup>
import {computed, ref} from 'vue'
import {$larafetch} from "@/utils/$larafetch";
import {useNuxtApp} from "nuxt/app";
import debounce from "lodash/debounce"

const {t: $t} = useI18n()

const props = defineProps({
    modelValue: {
        required: false,
        type: [Number, String],
        default: null,
        description: 'The selected option'
    },
    options: {
        required: false,
        type: Array,
        default: () => [],
        description: 'The options to display in the select if no api route is given'
    },
    apiRoute: {
        required: false,
        type: String,
        default: null,
        description: 'The api route to fetch the options from'
    },
    fetchOnSearch: {
        required: false,
        type: Boolean,
        default: false,
        description: 'If the options should only be fetched when a search is performed. When set to false, the options will be fetched on mounted'
    },
    filter: {
        required: false,
        type: Array,
        default: () => [],
        description: 'The filter to apply to the api route'
    },
    output: {
        required: false,
        type: String,
        default: 'select-object',
        description: 'The output of the api route'
    },
    searchPlaceholder: {
        required: false,
        type: String,
        default: '',
        description: 'The placeholder text of the search input'
    },
    label: {
        required: false,
        type: String,
        default: null,
        description: 'The label of the select'
    },
    displayKey: {
        required: false,
        type: String,
        default: 'name',
        description: 'The key of the value to display in the options'
    },
    identifier: {
        required: false,
        type: String,
        default: 'id',
        description: 'The key of the value that should be saved in the modelValue'
    },
    modelEntireObject: {
        type: Boolean,
        default: false,
        description: 'If the component should return the entire object instead of the value of the identifier'
    },
    searchThreshold: {
        required: false,
        type: Number,
        default: 2,
        description: 'The threshold of the search input'
    },
    required: {
        required: false,
        type: Boolean,
        default: false,
        description: 'If the select is required'
    },
    params: {
        type: Object,
        default: () => ({}),
        description: 'The params to send to the api route'
    },
    showInaccurateMatches: {
        type: Boolean,
        default: false,
        description: 'If the component should show options that do not match the query exactly'
    },
    allowArbitraryValue: {
        type: Boolean,
        default: false,
        description: 'If the component should allow arbitrary values'
    },
    disabledKey: {
        type: String,
        default: 'disabled',
        description: 'The key of the option that handles if the option is disabled'
    },
    disabled: {
        type: Boolean,
        default: false,
        description: 'If the select is disabled'
    },
})

const query = ref('')
const allOptions = ref([])
const selectedOption = ref(null)
const openOptions = ref(false)
const emit = defineEmits(['update:modelValue', 'update:entireObject', 'change'])

const availableOptions = computed(() => {
    const opts = allOptions.value
    if (props.allowArbitraryValue) {
        const arbitraryOption = {
            [props.identifier]: query.value,
            [props.displayKey]: query.value,
            arbitrary: true
        }
        if (!opts.find(e => e[props.identifier] === arbitraryOption[props.identifier])) {
            opts.push(arbitraryOption)
        }
    }
    return opts
})

const filteredOptions = computed(() => {
    if (props.showInaccurateMatches) return availableOptions.value
    const options = query.value === ""
        ? availableOptions.value
        : availableOptions.value.filter((option) => {
            return option[props.displayKey].toLowerCase().includes(query.value.toLowerCase())
        })

    if (selectedOption.value && !options.find((option) => option[props.identifier] === selectedOption.value[props.identifier])) {
        options.push(selectedOption.value)
    }

    return options
})

const placeholder = computed(() => {
    if (props.searchPlaceholder) {
        return props.searchPlaceholder
    } else if (props.apiRoute) {
        return 'Suche nach Name...'
    } else {
        return useI18n().t('pleaseSelect')
    }
})

const onType = (input) => {
    query.value = input
    if (props.fetchOnSearch && props.apiRoute) {
        pending.value = true
        onSearch()
    }
}

const onSearch = debounce(() => {
    if (query.value.length > props.searchThreshold) {
        return fetchOptions(query.value, props.filter, props.output, props.params)
    }
    allOptions.value = []
}, 500)

onMounted(async () => {
    // make sure all props are initialized
    await nextTick()
    if (props.apiRoute) {
        if (!props.fetchOnSearch) {
            fetchOptions(query.value, props.filter, props.output, props.params)
        } else {
            if (props.modelValue) {
                const filter = [
                    ...props.filter,
                    {
                        name: 'getSelected',
                        column: props.identifier,
                        operator: '=',
                        value: props.modelValue
                    }
                ]
                fetchOptions(query.value, filter, props.output, props.params)
            } else {
                setSelectedOption()
            }
        }
    } else {
        if (props.options.length) {
            setOptions()
            setSelectedOption()
        }
    }
})

watch(() => selectedOption.value, (value) => {
    if (value) {
        emit('update:modelValue', value[props.identifier])
        emit('update:entireObject', value)
    } else {
        emit('update:modelValue', null)
        emit('update:entireObject', {})
    }
})
watch(() => props.options, () => {
    if (props.options) {
        setOptions()
        setSelectedOption()
    }
})

function setOptions() {
    allOptions.value = props.options
}

function setSelectedOption() {
    selectedOption.value = allOptions.value.find((option) => option[props.identifier] === props.modelValue) ?? selectedOption.value
}

const pending = ref(false)
const loading = ref(false)

function fetchOptions(search, filter, output, params) {
    pending.value = true
    loading.value = true

    $larafetch(useNuxtApp().$apiRoute(props.apiRoute), {
        method: 'GET',
        params: {
            search: search,
            filter: filter,
            output: output,
            ...params
        }
    }).then(response => {
        if (output === 'select') {
            allOptions.value = Object.values(response)
        } else {
            allOptions.value = response.data
        }
        setSelectedOption()
    }).catch(error => {
        if (error && error.response && error.response.status === 422) {
            notification.error(app.$i18n.t('invalidRequestData'))
        } else if (error && error.response && error.response.status === 404) {
            notification.error(app.$i18n.t('modelDoesNotExist'))
        }
        throw error
    }).finally(() => {
        pending.value = false
        loading.value = false
    });
}

function clearInput() {
    onType('')
    selectedOption.value = null
}

function handleBlur() {
    openOptions.value = false
    if (props.modelValue === null) {
        clearInput()
    } else {
        onType('')
        setSelectedOption()
    }
}
</script>